import React from 'react';

import Layout from '../components/Layout';
import {MiniCard, MicroCard, ImageCard} from '../components/Card';



import EndofLifeImage from '../assets/images/icon-endoflife.svg';
import HiddenCostImage from '../assets/images/icon-hiddencosts.svg';
import InflexibleImage from '../assets/images/icon-inflexible.svg';
import LegacyImage from '../assets/images/icon-legacy.svg';
import MaintenanceImage from '../assets/images/icon-maintenance.svg';
import ModernizeImage from '../assets/images/icon-modernize.svg';
import ResourcesImage from '../assets/images/icon-resources.svg';
import RightImage from '../assets/images/icon-right.svg';
import LowCodeNoCodeImage from '../assets/images/icon-flow.svg';
import PublicSectorImage from '../assets/images/icon-publicsector.svg';
import CloudImage from '../assets/images/icon-cloudenable.svg';
import TalentImage from '../assets/images/icon-talent.svg';
import TransformImage from '../assets/images/icon-transform.svg';
import SolutionsImage from '../assets/images/icon-inventory.svg';
import MicrosoftLogo from '../assets/images/ms-partner-logo-gr.png';
import GraphiteLogo from '../assets/images/graphitegtc-logo-gr.svg';
import D365Logo from '../assets/images/D365-logo-gr.png';

const herobg = '#1c2841', herofcolor='#efefef';
const ModernizePage = () => (
  <Layout>
      <div id="main row" className='col-12' >
        <div className='row' style={{backgroundColor:herobg, color:herofcolor, padding:'2em 0'}}>
            <header className='col-9 inner' style={{margin:'.25em auto'}}>
                        <h3 >Modernize your solutions</h3>
                        <h1 style={{letterSpacing:'.1em'}}>
                            Are you putting up with inflexible legacy Solutions? Break the shackles.
                        </h1>
                </header>
        </div>
    
        
        <div className="inner row" >
            <div className='col-9 col-12-medium' style={{margin:'.25em auto'}}>
                <span className="image left">
                    <img src={ModernizeImage} alt="Modernize Solutons" style={{width:'120px'}} />
                </span>
            
                <p style={{ fontSize:'90%', margin:'.5em auto 1em', textAlign:'justify'}}>
                    Legacy systems are often seen as holding back the business initiatives that 
                    rely on them. On the other side 70 to 80 percent of the IT budget is spent 
                    on operating and maintaining legacy systems and there’s not much left to seize 
                    new opportunities and drive the business forward. 
                
                </p> 
                <p style={{fontSize:'110%', fontWeight:400, margin:'1em auto .5em'}}>Why modernize?</p>
           
            
                <div className="inner row" style={{display:'flex', flexWrap:'wrap',margin:'.25em auto'}}>
                    <MiniCard cardImage={InflexibleImage} cardTitle="Existance of inflexible systems" />
                    
                    <MiniCard  cardImage={EndofLifeImage} cardTitle="Technology reaches end of life" />
                    
                    <MiniCard cardImage={MaintenanceImage} cardTitle="License & Maintenance cost explode" />
                    
                    <MiniCard cardImage={ResourcesImage} cardTitle="Resources become expensive" />
                    
                    <MiniCard cardImage={HiddenCostImage} cardTitle="Hidden Costs" />
                    
                    <MiniCard cardImage={LegacyImage} cardTitle="Uncertainity of legacy platforms" />

                </div>
                
            
                
                <p style={{fontSize:'110%', fontWeight:400, margin:'1em auto .5em'}}>
                    We use latest technologies and tools that are integration-ready and cost effective.
                </p>
                
                <p style={{fontSize:'110%', fontWeight:400, margin:'1em auto .5em'}}>Our Solution</p>
                <p style={{ fontSize:'90%', margin:'.5em auto 1em', textAlign:'justify'}}>
                    We specialize in accelerated application development for enterprises. We use low-code/no-code 
                    development environments to modernize the legacy solutions. Our solution -
                </p>

                <div className="inner row" style={{display:'flex', flexWrap:'wrap', margin:'.25em auto'}}>
                    
                    
                
                    <MiniCard className='col-6' cardImage={RightImage} cardTitle="Solves Capacity problems" />
                    <MiniCard className='col-6' cardImage={RightImage} cardTitle="Makes the business functionality future-proof" />
                    <MiniCard className='col-6' cardImage={RightImage} cardTitle="Reduces dependency on technology skillset" />
                    <MiniCard className='col-6' cardImage={RightImage} cardTitle="Reduces time to market" />
                    <MiniCard className='col-6' cardImage={RightImage} cardTitle="Reduces cost of development" />
                    
                </div>
            </div>
        
            <div className='col-3 col-6-medium col-12-small' >
                <p style={{fontWeight:400, fontSize:'90%', margin:'1em 0 .25em'}}>Explore</p>
                <MicroCard className='col-12 col-6-medium' 
                            cardTitle="Transformation" cardImage={TransformImage} 
                            cardLink='/transform-your-business'/>
                <MicroCard className='col-12 col-6-medium' 
                        cardTitle="Solutions on the cloud" cardImage={SolutionsImage} 
                        cardLink='/business-solutions-on-the-cloud'/>
                <MicroCard className='col-12 col-6-medium' 
                    cardTitle="Public Sector Services" cardImage={PublicSectorImage} 
                    cardLink='/public-sector-services'/>
                <MicroCard className='col-12 col-6-medium' 
                        cardTitle="Talent Services" cardImage={TalentImage} 
                        cardLink='/talent-services'/>
                <MicroCard className='col-12 col-6-medium' 
                    cardTitle="Low Code/No Code" cardImage={LowCodeNoCodeImage} 
                    cardLink='/lowcode-nocode'/>
                <MicroCard className='col-12 col-6-medium' 
                        cardTitle="Enable the Cloud" cardImage={CloudImage} 
                        cardLink='/enable-the-cloud'/>
                
                
                <p style={{fontWeight:400, fontSize:'90%', margin:'1em 0 .25em'}}>Technology Partners</p>
                <ImageCard className='col-12 col-6-medium' cardImage={GraphiteLogo} height='20px'/>
                <ImageCard className='col-12 col-6-medium' cardImage={MicrosoftLogo} />   
                <ImageCard className='col-12 col-6-medium' cardImage={D365Logo} height='35px'/>   
        </div>
        </div>
    </div>
  </Layout>
);

export default ModernizePage;
